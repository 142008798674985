


































import { computed } from '@vue/composition-api';

export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    singleLine: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    defaultClasses: {
      type: String,
      default: ''
    },
    toggleShow: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    keyupEnter: {
      type: Function,
      default: () => {}
    },
    errorMessages: {
      type: Array,
      default: () => {}
    },
    styling: {
      type: Object,
      default: () => {}
    }
  },

  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { inputValue };
  }
};
